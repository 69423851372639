// preformatting tooltip
.introjs-tooltiptext {
  white-space: pre-line;
}
.DOC_WEB_app_header {
  z-index: auto !important;
}

.intro-js-hide-prev-button > .introjs-tooltipbuttons > a.introjs-prevbutton {
  display: none;
  height: 100px;
  background-color: red;
}

.DOCWeb_IntroStartContainer {
  margin-left: 30%;
  width: 600px;
}

.DOCWeb_IntroStartContainer > 
  .ABB_CommonUX_Dialog__root {
  min-width: 200px;
  border-top: none;
}
.DOCWeb_IntroStartContainer >
  .ABB_CommonUX_Dialog__root >
    .ABB_CommonUX_Dialog__content {
  width: auto !important;
}

.DOCWeb_intro_start {
  text-align: center;
}

.DOCWeb_intro_dialog_start_button {
  width: 90%;
  min-width: 90% !important;
}