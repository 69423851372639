.rc-slider {
  width: 90% !important;
  height: 4px !important;
}

.rc-slider-rail {
  background-color: rgb(219, 219, 219) !important;
}

.rc-slider-handle {
  background-color: #ffffff !important;
  border: 2px solid #3366ff !important;
  height: 16px !important;
  width: 16px !important;
  margin-left: 0px !important;
  margin-top: -6px !important;
}
.rc-slider-handle:hover,
.rc-slider-handle:focus {
  background-color: #3366ff !important;
  border: 2px solid #3366ff !important;
}
.rc-slider-min-value {
  position: absolute !important;
  top: 20px !important;
  left: 10px !important;
}

.rc-slider-max-value {
  position: absolute !important;
  top: 20px !important;
  right: 10px !important;
}

.rc-slider-disabled {
  background: none !important;
}