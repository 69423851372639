 table > tbody{
  display:block;
  max-height: 632px;
  overflow-y:scroll;
}

 table > thead,  table > tbody tr {
  display:table;
  width:100%;
  table-layout:fixed;
}

 table > thead {
  width: calc(100% - 5px)
}

.scrollable-tbody > table > tbody{
	max-height: 200px; /*Customize your tbody height*/
}
.DOCweb_CommonUX__Table {
  border-spacing: 1px;
  border-collapse: collapse;
  width: 100%;
}

.DOCweb_CommonUX__TableHeader__NoHover,
.DOCweb_CommonUX__TableHeader {
  // background-color: #dbdbdb;
  height: 30px;
  vertical-align: middle;
  border-bottom: 1px solid #CACACA;
  font-weight: normal;
  cursor: pointer;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.DOCweb_CommonUX__TableHeader:hover {
  border-bottom: 2px solid #3366FF;
}
.DOCweb_CommonUX__TableHeader_Icon {
  vertical-align: sub;
  margin-left: 3px;
}

.DOCweb_CommonUX__TableItem_Selected {
  background-color: #3366FF;
  color: white;
}

.DOCweb_CommonUX__TableItem_Selected_Scenarios > tbody > tr > td {
  background-color: white !important;
  color: black;
}

.DOCweb_CommonUX__TableItem_Selected_Scenarios > tbody > .DOCweb_CommonUX__TableItem:hover > td {
  border: 1px solid #bababa !important;
}

.DOCweb_CommonUX__TableItem:hover {
  background-color: #4C85FF;
  color: white;
}
// .DOCweb_CommonUX__TableItem_Selected > td {
//   // border: 1px solid rgb(123, 152, 216) !important;
//   background-color: #3366FF;
//   color: white;
// }
// .DOCweb_CommonUX__TableItem:hover > td {
//   // border: none !important;
// }
// .DOCweb_CommonUX__TableItem > td{
//   border: 1px solid #bababa;
//   cursor: pointer;
//   height: 30px;
//   padding-left: 15px;
// }
.DOCweb_CommonUX__TableItem_Delete:hover {
  color: #3366FF;
  background-color: #FFF;
  border: 1px solid #bababa !important;
}
.DOCweb_CommonUX__TableItem_Delete {
  padding-left: 0 !important;
  text-align: center;
}
// .DOCweb_CommonUX__TableItem_Disabled:hover {
//   background-color: transparent;
//   color: black;
// }
.DOCweb_CommonUX__TableItem_Disabled {
  opacity: 0.5;
  cursor: no-drop;
  pointer-events: none;
}

.DOCweb_CommonUX__TableItem > .docweb-table-centered-text {
  padding-left: 0;
  text-align: center;
}

.DOCweb_CommonUX__Table__Search {
  width: 265px;
  padding-bottom: 20px;
  padding-top: 10px;
}

.DOCweb_CommonUX__Table__Search > span > input {
  border: none !important;
  border-bottom: 1px solid #CACACA !important;
  width: 280px;
  background-color: #EBEBEB !important;
}

.DOCweb_CommonUX__TableItem > td{
  border-bottom: 2px solid #D2D2D2;
  cursor: pointer;
  height: 30px;
  padding-left: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}
.DOCweb_CommonUX__TableItem > td > span {
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  vertical-align: inherit;
  width: 100%;
}

.DOCweb_CommonUX__Table >tbody::-webkit-scrollbar-thumb {
  background: #aaa;
}

.DOCweb_CommonUX__Table >tbody::-webkit-scrollbar-thumb:hover {
  background: #999;
}

.DOCweb_CommonUX__TableItem_Selected {
  background: #08f;
}

.DOCweb_CommonUX__TableItem_Selected:hover {
  background: #aaa;
}