/**
 * Quick access toolbar
 */
 .quick-access-toolbar {
  // margin-top: 51px;
  // height: 150px;
  // $quick-access-toolbar-height;
  // font-size: 20px;
  // padding: 0;
  // background-color: $abb-grey-230;
  // height: 170px;
  border-top: 1px solid #e6e6e6;
  flex-wrap: nowrap;
  min-width: 700px;
  margin: 0 0 0 -6px;
}
.toolbar-items {
  overflow: hidden;
  padding-bottom: 20px;
}
.quick-access-toolbar-items {
  padding: 0;
  height: 33px;
}

.quick-access-toolbar-project-name {
  text-align: right;
  font-family: ABBvoice-bold, sans-serif;
  padding-right: 5px;
}

.quick-access-toolbar-items > div,
._react-file-reader-input {
  display: inline-block;
}
.quick-access-toolbar-project-name .group-5 {
  vertical-align: bottom;
  display: inline-flex;
  padding-left: 10px;
}
.quick-access-toolbar-project-name .group-5 > div {
  padding-right: 40px;
}
.quick-access-toolbar-project-name .group-5 > div:first-child {
  width: 260px;
  padding-right: 0px;
}
.quick-access-toolbar-project-name .group-5 > div:last-child {
  padding-top: 5px;
  padding-right: 0px;
}
.quick-access-toolbar-items .group-1 {
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 2px;
}

.quick-access-toolbar-items .group-2,
.quick-access-toolbar-items .group-3 {
  // border-right: 1px solid $abb-grey-140;
  padding-left: 15px;
  padding-right: 5px;
}

.quick-access-toolbar-items .group-4 {
  padding-left: 15px;
}
