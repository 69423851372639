.object-properties-input,
.object-properties-checkbox {
  padding: 0;
  height: 100%;
  padding-top: 0 !important;
}

.object-properties-name,
.object-properties-divider,
.object-properties-dimensions {
  height: 33px;
  text-align: center;
  vertical-align: middle;
  color: #1f1f1f;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 12px;
}

.object-properties-dimensions,
.object-properties-divider,
.object-properties-name,
.object-properties-input,
.object-properties-checkbox {
  padding: 0;
}

.object-properties-name > .object-properties-name-label {
  vertical-align: middle;
}

 .object-properties-dimensions label,
 .object-properties-name label,
 .object-properties-protected label,
 .object-properties-divider label {
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     margin-right: -50%;
 }
 
 .object-properties-dimensions label,
 .object-properties-protected label,
 .object-properties-divider label {
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     margin-right: -50%;
 }
 
 .object-properties-dimensions label {
     text-align: center;
     width: 100%;
 }
 
 .object-properties-name label {
     text-align: left;
     width: 100%;
     padding-left: 5px;
 }
 
 .object-properties-name-value label{
     text-align: right;
     width: 100%;
     padding-right: 5px;
 }
 
 .object-properties-protected label{
     text-align: center;
 }
 
 .object-properties-left-border {
     border-left: 1px solid #9f9f9f;
 }
 
 .object-properties-name {
     overflow-wrap: break-word;
     border-right: 1px solid #9f9f9f;
 }
 
 .object-properties-dimensions {
     text-align: left;
     float: left;
     border-left: 1px solid #9f9f9f;
 }
 
 .object-properties-error-message {
     color: $abb-alarm-red;
 }
 
 .object-properties-error-message label {
     width: 95%;
     text-align: center !important;
 }

.object-properties-input > .ABB_CommonUX_Input__root > .ABB_CommonUX_Input__inputWrapper > input,
.object-properties-input > .DOCweb_CommonUX_Select__wrapper > .DOCweb_CommonUX_Select__custom {
    border: none !important;
}
.object-properties-input > .ABB_CommonUX_Input__root >  .ABB_CommonUX_Input__validationCollapsed {
    display: none;
}

.object-properties-checkbox {
    border-left: 1px solid #9f9f9f;
}