$abb-grey-255: #FFFFFF;
$abb-grey-250: #FAFAFA;
$abb-grey-240: #F0F0F0;
$abb-grey-230: #E6E6E6;
$abb-grey-220: #DCDCDC;
$abb-grey-210: #D2D2D2;
$abb-grey-200: #C8C8C8;
$abb-grey-180: #B4B4B4;
$abb-grey-170: #AAAAAA;
$abb-grey-160: #A0A0A0;
$abb-grey-140: #8C8C8C;
$abb-grey-110: #6E6E6E;
$abb-grey-90: #5A5A5A;
$abb-grey-70: #464646;
$abb-grey-50: #323232;
$abb-grey-38: #262626;
$abb-grey-20: #141414;
$abb-grey-0: #000000;


// ABB Blue scale

$abb-blue-1: #DDEDFD;
$abb-blue-2: #ABD4FD;
$abb-blue-3: #73B7FF;
$abb-blue-4: #2E92FA;
$abb-blue-5: #0C74DA;
$abb-blue-6: #004C97;


// ABB Data visualization colors

$abb-purple-1: #BAACDD;
$abb-purple-2: #A288CE;
$abb-purple-3: #8664BF;
$abb-purple-4: #6C46AA;
$abb-purple-5: #553786;
$abb-purple-6: #462D6E;
$abb-pink-1: #DAA5CE;
$abb-pink-2: #C675B3;
$abb-pink-3: #B7519F;
$abb-pink-4: #973E82;
$abb-pink-5: #732F63;
$abb-pink-6: #5B254E;
$abb-peach-1: #F3C8B8;
$abb-peach-2: #ECA88F;
$abb-peach-3: #DE8262;
$abb-peach-4: #CB6745;
$abb-peach-5: #A65033;
$abb-peach-6: #833B22;
$abb-gold-1: #F3E7B5;
$abb-gold-2: #F1DD8E;
$abb-gold-3: #E1C96C;
$abb-gold-4: #CBAF49;
$abb-gold-5: #9E842F;
$abb-gold-6: #7A641B;
$abb-green-1: #C0ECD2;
$abb-green-2: #92DEB1;
$abb-green-3: #5CBE85;
$abb-green-4: #329A5D;
$abb-green-5: #007A33;
$abb-green-6: #005C27;
$abb-turquoise-1: #B9EEF0;
$abb-turquoise-2: #90E4E6;
$abb-turquoise-3: #58D7DA;
$abb-turquoise-4: #28B1B5;
$abb-turquoise-5: #1F888B;
$abb-turquoise-6: #196D6F;
$abb-asphalt-1: #D7E4E9;
$abb-asphalt-2: #BCD0D4;
$abb-asphalt-3: #98B6BC;
$abb-asphalt-4: #80A0A6;
$abb-asphalt-5: #628288;
$abb-asphalt-6: #4B656A;


// ABB Alarm colors

$abb-alarm-yellow: #FFD800;
$abb-alarm-orange: #FF7300;
$abb-alarm-red: #F03040;
$abb-alarm-magenta: #CB2BD5;
$abb-alarm-cyan: #14F0F0;
$abb-alarm-blue: #0052FF;

:export {
  abbGrey0: $abb-grey-0;
  abbGrey90: $abb-grey-90;
  abbGrey200: $abb-grey-200;
  abbGrey220: $abb-grey-220;
  abbGrey240: $abb-grey-240;
  abbGrey255: $abb-grey-255;
  abbBlue4: $abb-blue-4;
}