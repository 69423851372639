$number-of-right-panels: 1;
$number-of-left-panels: 1;
$number-of-center-panels: 1;

.ABB_CommonUX_AppContent__root.ABB_CommonUX_AppContent__light.DOCweb__CommonUX_AppContent_Properties.DOCweb__CommonUX_AppContent_Properties_Max_Width {
  border-left: 1px solid silver;
}
.ABB_CommonUX_AppLeftPane__root {
  max-height: 100%;
}
.ABB_CommonUX_AppLeftPane__root, .ABB_CommonUX_AppLeftPane__root:not(.ABB_CommonUX_AppLeftPane__showVisibilityToggle)>.ABB_CommonUX_AppLeftPane__leftPaneContent  {
  margin-top: 0px !important;
}
.ABB_CommonUX_AppLeftPane__root.ABB_CommonUX_AppLeftPane__semi {
  width: 450px;
}

.ABB_CommonUX_AppLeftPane__root.ABB_CommonUX_AppLeftPane__expanded {
  width: 350px;
}

.ABB_CommonUX_AppLeftPane__expanded_mobile {
  width: 50% !important;
}
.ABB_CommonUX_AppLeftPane__expanded_tablet {
  width: 300px !important;
}
.ABB_CommonUX_AppLeftPane__root.ABB_CommonUX_AppLeftPane__hidden {
  width: 40px;
}

.DOCweb__CommonUX_AppContent_Properties > div,
.ABB_DOCweb_Expander__custom__right,
.ABB_DOCweb_Expander__custom__center {
    width: 100%;
}

.ABB_DOCweb_Expander__custom__center >.ABB_CommonUX_Expander__content {
  height: calc( (100vh - (#{$app-panel-header-height} * #{$number-of-center-panels}) - #{$quick-access-toolbar-height} - #{$app-panel-footer-height} - #{$app-navbar-height} - 1px * #{$number-of-center-panels}) / 3 );
  background-color: white;
  overflow-y: scroll;
}

.ABB_DOCweb_Expander__custom__center .ABB_CommonUX_Expander__header span:last-child {
  width: calc(100% - 48px);
}

.DOCweb__CommonUX_AppContent_Console {
  z-index: 0;
  flex: 0;
  width: 100%;
}

.ABB_CommonUX_AppLeftPane__root>.ABB_CommonUX_AppLeftPane__collapserIcon {
  margin-top: 6px;
}
.DOCweb__CommonUX_AppLeftPane_Stencil {
  width: 350px;
  min-width: 40px;
}
.DOCweb__CommonUX_AppLeftPane_Stencil > .ABB_CommonUX_AppLeftPane__collapserIcon {
  margin-top: -8px;
  margin-left: -3px;
}
.ABB_CommonUX_AppLeftPane__collapsed > .ABB_CommonUX_AppLeftPane__collapserIcon > i::before {
  content: "\e96f" !important;
}
.ABB_CommonUX_Expander__root>.ABB_CommonUX_Expander__header>span:last-child {
  margin: 0;
}

.ABB_DOCweb_Expander__custom__left >.ABB_CommonUX_Expander__content {
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc( 100vh - (#{$app-panel-header-height} * #{$number-of-left-panels}) - #{$quick-access-toolbar-height} - #{$app-panel-footer-height} - #{$app-navbar-height-left});
  background-color: white;
}
.ABB_DOCweb_Expander__custom__right>.ABB_CommonUX_Expander__content{
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: white;
  height: calc( 100vh - (#{$app-panel-header-height} * #{$number-of-right-panels}) - #{$quick-access-toolbar-height} - #{$app-panel-footer-height} - #{$app-navbar-height} - 1px * #{$number-of-right-panels} );
  min-width: 280px;
}

.app-panel-body-right {
  height: calc( 100vh - (#{$app-panel-header-height} * #{$number-of-right-panels}) - #{$quick-access-toolbar-height} - #{$app-panel-footer-height} - #{$app-navbar-height} - 1px * #{$number-of-right-panels} );
  padding-top: 0;
}

.app-panel-body-center {
  height: calc( (100vh - (#{$app-panel-header-height} * #{$number-of-right-panels}) - #{$quick-access-toolbar-height} - #{$app-panel-footer-height} - #{$app-navbar-height} - 1px * #{$number-of-center-panels}) / 2 );
  padding-top: 0;
}

.app-panel-body-left {
  // ($app-panel-header-height * $number-of-right-panels) - $app-panel-footer-height - $app-navbar-height
  height: calc( 100vh - (#{$app-panel-header-height} * #{$number-of-left-panels}) - #{$quick-access-toolbar-height} - #{$app-panel-footer-height} - #{$app-navbar-height} - 1px * #{$number-of-left-panels} );
}

.ABB_CommonUX_AppLeftPane__root.ABB_CommonUX_AppLeftPane__showRightBorder {
  border-right: 2px solid #C3C3C3;
}
