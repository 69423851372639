$color: red;

/**
 * Styles for login page
 */
 .background-image {
  width: 100vw;
  height: 100vh;
  // TODO: fix css image loading
  // background-image: url('/assets/abb-background.jpg');
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  position: absolute;
}

.login-page {
  background-color: rgba(255,255,255,0.96);
  text-align: center;
  width: 26%;
  margin: auto;
  margin-top: 100px;
  height: 550px;
  min-width: 500px;
}

.login-page .modal-header img {
  height: 120px;
}

.login-page .modal-body {
  padding-top: 65px;
}

.login-page .modal-body h1 {
  font-size: 64px;
  font-family: ABBvoice-bold, Arial, Helvetica, sans-serif;
}

.login-page .modal-body hr {
  border-top: 8px solid $abb-alarm-red;
  width: 60px;
  margin: auto;
}

.loader {
  font-size: 10px;
  margin: 20px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: $color;
  background: -moz-linear-gradient(left, $color 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, $color 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, $color 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, $color 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, $color 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: $color;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.loader:after {
  background: white;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
