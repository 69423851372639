.device-parameters-expander {
  width: 100%;
  background-color: 'lightgray' !important;

}

// .custom-curves-device-parameters .ABB_CommonUX_Expander__header {
//     background-color: 'lightgray';
// }
.custom-curves-device-parameters {
  margin-bottom: 8px;
}

.custom-curves-device-parameters>.ABB_CommonUX_Expander__header {
  background: lightgray;
  border-bottom: none;
  }

.custom-curves-device-parameters>.ABB_CommonUX_Expander__content {
  background: lightgray;
  margin: 0;
  border-bottom: none;
  padding-left: 10px;
  padding-top: 12px;
}

.custom-curves-device-parameters>.ABB_CommonUX_Expander__root>.ABB_CommonUX_Expander__header>span:last-child {
  height: 40px;
}

.custom-curves-device-parameters>.ABB_CommonUX_Expander__root>.ABB_CommonUX_Expander__header>span:first-child>* {
  margin: 12px;
}

.custom-curves-device-parameters-input {
  height: 18px;
}

.custom-curves-device-parameters-input>.ABB_CommonUX_Input__inputWrapper {
  border: none;
  height: 18px;
}

.custom-curves-device-parameters-input>.ABB_CommonUX_Input__inputWrapper>input {
  text-align: center;
}

.curvePoint-icon {
  display: hidden;
  margin-right: 12px;
}

.curvePoint-icon:first-child {
  padding-left: 12px;
}

.curvePoint-icon:hover {
  cursor: pointer;
  color: #36f;
}

.curvePoint-row {
  margin-top: 8px;
  height: 18px;
}

.curvePoint-row:hover {
  background-color: #36f;
}

.labelBold>div>span {
  font-weight: 600 !important;
  color: black;
}

.curvePointRow-iconColumn {
  height: 18px;
}

.labelBold {
  font-weight: 600;
  color: black;
}

.deviceManagerOptions {
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 2px;
  width: 197px;
  height: 127px;
  min-width: 197px;
  min-height: 127px;
  z-index: 1;
}

.deviceManagerOverlay {
  background: black;
  opacity: 0.2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
}

.deviceMenuRow {
  margin-top: 16px;
  margin-left: 16px;
}

.deviceMenuRow:last-child {
  color: red;
}

.deviceManagerClickableText:hover {
  cursor: pointer;
  text-decoration:underline;
}

.deviceParametersRadio {
  width: 0px;
}

.deviceParametersRadio > div > label > span::before,
.deviceParametersRadio > div > label > span::after {
  left: 0px !important;
}

.deviceParametersRadio>.ABB_CommonUX_RadioGroup__root>label>input:checked+span:nth-child(2):after {
  left: 5px !important;
}

.custom-curves-library-row {
  margin: 0 auto 4px;
  width: 92%;
  border-radius: 3px;
  background-color: lightGray;
  height: 40px;
}

.custom-curves-library-text {
  padding-left: 16px;
  font-weight: 600;
}

.clickable-text {
  font-weight: 600;
  text-decoration: underline;
  color: #3366ff;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
}

.device-parameters-expander>.ABB_CommonUX_Expander__content {
  padding-left: 8px;
}

.custom-devices-checkbox-style {
  margin-right: 12px;
}
