.DOCWEB_device-message-error,
.DOCWEB_device-message-error i {
    color: $abb-alarm-red !important;
}

.DOCWEB_device-message-warning,
.DOCWEB_device-message-warning i {
    color: $abb-alarm-orange !important;
}

.DOCWEB_device-message-curve-notification > span {
    background-color: $abb-grey-255 !important;
}
.DOCWEB_device-message-curve-notification {
    border: 1px solid #ebebeb;
}
.DOCWEB_device-message-curve-notification,
.DOCWEB_device-message-curve-notification i {
    color: #0CA919 !important;
}

// little fix to modal dialog window size (space below button was a bit too small)
.ABB_CommonUX_Dialog__root>.ABB_CommonUX_Dialog__content {
    padding: 16px 16px 16px 32px
}

// fix in small screens modal dialog window was stretched down to lower edge of the screen
@media (max-width: 900px) {
    .ABB_CommonUX_Dialog__root {
        height: fit-content
}
}

// allow preformatted text in MessageDialog

.DOCWEB_Message_Dialog_Pre_Format_Text > .ABB_CommonUX_Dialog__content > .ABB_CommonUX_MessageDialog__message {
    white-space: pre-line,
}

.calculations {
    overflow: scroll;
    padding: 0px 0px 0px 5px;
}