.root {
}

.root :global .curve-function {
  margin-top: 15px;
}

.root :global .curve-function .curve-function-row {
  margin-top: 5px;
}

.root :global .curve-function .curve-function-row .radio-buttons {
  text-align: left;
}
