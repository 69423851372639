// .curve-function-row > .DOCweb__CommonUX_GridColumn4:first-child {
//   padding-right: 5px;
// }
.curve-function {
  padding: 20px;
  background-color: $abb-grey-255;
  border-bottom: 2px solid #ebebeb;
}
.curves-refresh {
  margin-top: 2px;
  margin-bottom: 2px;
  padding-right: 5px;
  color: #3366FF;
}

.curves-properties-header {
  border-bottom: 2px solid black;
  margin: 20px;
}
.curves-properties-header > h3 {
  font-family: ABBVoice-bold, sans-serif;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 20px;
}
.curves-properties-header-tooltip > span {
  width: 100%;
}

.curves-show-tab {
  background-color: $abb-grey-255;
}

.curves-show-row {
  border-bottom: 2px solid #ebebeb;
  text-align: left;
}

.curves-show-input-column,
.curves-show-text-column {
  padding-left: 10px;
  padding-top: 5px;
}
.curves-show-eye-column {
  text-align: center;
  padding-top: 5px;
}
