// Row
.DOCweb__CommonUX_Grid {
  display: block;
  width: 100%;
}
.DOCweb__CommonUX_GridRow {
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}


// Aligments
.DOCweb__CommonUX_GridRow_start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  text-align: start;
}

.DOCweb__CommonUX_GridRow_center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.DOCweb__CommonUX_GridRow_end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: end;
}

.DOCweb__CommonUX_GridRow_top {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.DOCweb__CommonUX_GridRow_middle {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.DOCweb__CommonUX_GridRow_bottom {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}


// Columns
.DOCweb__CommonUX_GridColumn {
  // AutoWidth
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%;
}
.DOCweb__CommonUX_GridColumn0 {
  flex-basis: 0%;
  max-width: 0%;
  display: none;
}
.DOCweb__CommonUX_GridColumn1 {
  flex-basis: calc(1/8 * 100%);
  max-width: calc(1/8 * 100%);
}
.DOCweb__CommonUX_GridColumn2 {
  flex-basis: calc(2/8 * 100%);
  max-width: calc(2/8 * 100%);
}
.DOCweb__CommonUX_GridColumn3 {
  flex-basis: calc(3/8 * 99.6%);
  max-width: calc(3/8 * 100%);
}
.DOCweb__CommonUX_GridColumn4 {
  flex-basis: calc(4/8 * 100%);
  max-width: calc(4/8 * 100%);
}
.DOCweb__CommonUX_GridColumn5 {
  flex-basis: calc(5/8 * 100%);
  max-width: calc(5/8 * 100%);
}
.DOCweb__CommonUX_GridColumn6 {
  flex-basis: calc(6/8 * 100%);
  max-width: calc(6/8 * 100%);
}
.DOCweb__CommonUX_GridColumn7 {
  flex-basis: calc(7/8 * 100%);
  max-width: calc(7/8 * 100%);
}
.DOCweb__CommonUX_GridColumn8 {
  flex-basis: calc(8/8 * 100%);
  max-width: calc(8/8 * 100%);
}
.DOCweb__CommonUX_GridColumn,
.DOCweb__CommonUX_GridColumn1,
.DOCweb__CommonUX_GridColumn2,
.DOCweb__CommonUX_GridColumn3,
.DOCweb__CommonUX_GridColumn4,
.DOCweb__CommonUX_GridColumn5,
.DOCweb__CommonUX_GridColumn6,
.DOCweb__CommonUX_GridColumn7,
.DOCweb__CommonUX_GridColumn8 {
  position: relative;
}
// Offsets
.DOCweb__CommonUX_GridColumn_Offset1 {
  margin-left: calc(1/8 * 100%);
}
.DOCweb__CommonUX_GridColumn_Offset2 {
  margin-left: calc(2/8 * 100%);
}
.DOCweb__CommonUX_GridColumn_Offset3 {
  margin-left: calc(3/8 * 100%);
}
.DOCweb__CommonUX_GridColumn_Offset4 {
  margin-left: calc(4/8 * 100%);
}
.DOCweb__CommonUX_GridColumn_Offset5 {
  margin-left: calc(5/8 * 100%);
}
.DOCweb__CommonUX_GridColumn_Offset6 {
  margin-left: calc(6/8 * 100%);
}
.DOCweb__CommonUX_GridColumn_Offset7 {
  margin-left: calc(7/8 * 100%);
}

.DOCweb__CommonUX_GridColumn_top {
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.DOCweb__CommonUX_GridColumn_middle {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.DOCweb__CommonUX_GridColumn_bottom {
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
