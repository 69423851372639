@media print {.copyright-page-space-rectangle { page-break-after: always; }}@page { size: auto;  margin: auto;}
.page {
  height: 8px;
  width: 16px;
  color: #000000;
  font-family: ABBvoice, sans-serif;
  font-size: 6px;
  letter-spacing: -0.02px;
}
.copyright-page-space-rectangle,
.page-number-rectangle {
  width: 740px;
}
.rectangle {
  box-sizing: border-box;
  min-height: 843px;
  width: 740px;
  border: 1px solid #EBEBEB;
  height: calc(100% - 20px);
  
}
.page-number {
  font-family: ABBvoice, sans-serif;
  font-size: 13px;
  line-height: 19px;
}

.group-77-copy {
  height: 14px;
  width: 502px;
}
.curves-data-keys {
  font-family: ABBvoice-medium, sans-serif;
  font-size: 10px;
  line-height: 12px;
}
.device-product-name {
  // color: #808080 !important;
  font-family: ABBvoice-medium, sans-serif;
  font-size: 20px;
  letter-spacing: 0.8px;
  line-height: 25px;
  padding-left: 95px;
}

.curves-data-rows {
  text-align: left;
  padding-left: 95px;
}

.curves-device-settings {
  text-align: left;
  padding-left: 95px;
}

.report-logo {
  width: 100px;
  height: 50px;
  margin: 27px 27px 0 0;
  float: right;
}

.report-svg-image {
  width: 666px;
  margin-bottom: -40px;
}

.diagramName {
  justify-content: left;
  padding-left: 95px;
  font-size: 15px;
  font-family: ABBVoice-medium, sans-serif;
  padding-top: 20px;
}

.device-stripe {
  background-color: #FF000F;
  width: 18px;
  height: 2px;
  position: absolute;
}

.copyright {
	height: 8px;
	width: 36px;
	color: #000000;
	font-family: ABBvoice, sans-serif;
	font-size: 6px;
}

.abb-all-rights-reserved {
	height: 10px;
	width: 640px;
	color: #000000;
	font-family: ABBvoice, sans-serif;
  font-size: 6px;
  border-bottom: 1px solid black;
  border-top: 1px solid #A9A9A9;
  margin: -25px auto;
}

.bot-footer-line {
	height: 1px;
	background-color: #000000;
}

.top-footer-line {
	height: 1px;
	background-color: #A9A9A9;
}

.copyright-page-space {
  margin-left: auto;
}

.device-setting-name {
  font-size: 16px;
  line-height: 19px;
  color: #808080 !important;
}
