html {
  overflow: hidden;
}

ul, menu, dir {
  padding: 0px;
  margin: 0px;
}

.ABB_CommonUX_AppHeader__root {
  min-height: auto !important;
}

.ABB_CommonUX_TabControl__root > .ABB_CommonUX_TabControl__content {
  overflow-x: hidden;
}
.ABB_CommonUX_TabControl__root {
  padding: 5px;
}

.ABB_CommonUX_AppContent__root.ABB_CommonUX_AppContent__light {
  min-width: 0;
  min-height: 0;
  position: relative;
}

.ABB_CommonUX_AppContent__root.ABB_CommonUX_AppContent__light.DOCweb__CommonUX_AppContent_Console {
  position: absolute;
  bottom: 0;
}